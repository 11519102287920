@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap");
* {
    box-sizing: border-box;
}
body {
    box-sizing: border-box;
    /* font-family: "Inter" sans-serif; */
    overflow: hidden;
}

li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
ul {
    margin: 0;
    padding: 0;
}
p {
    margin: 0;
}
a {
    text-decoration: none !important;
}
Link {
    text-decoration-color: #000 !important;
}
button {
    outline: none !important;
    box-shadow: none;
    border: 1px solid gray;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

button:hover {
    cursor: pointer;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-btn-default {
    box-shadow: none !important;
}

.page-content {
    background-color: #f8f8f8;
    display: block;
    height: 100%;
    overflow: auto;
    padding: 0 0 45px;
    position: relative;
    width: 100%;
}
.page-content .page-center-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 100px;
    font-weight: 600;
    background: linear-gradient(173deg, #44f082, #86d6ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main_table_ag .ag-row-selected {
    background-color: #00808021 !important;
}
.ag-theme-alpine.ag-main-table {
    font-size: 12px !important;
}
.ag-theme-alpine .ag-cell {
    font-size: 12px !important;
}

.main_table_ag :where(.css-dev-only-do-not-override-14qglws).ant-btn {
    font-size: 18px;
    line-height: 1.5714285714285714;
    padding: 0px 5px;
    border-radius: 6px;
}
.main_table_ag .Complete,
.statu_of_image_view {
    color: #5eab5e;
    background-color: #cafaca80;
    font-weight: 700;
    padding: 6px 15px;
    margin: 5px 0;
    border-radius: 5px;
}
.statu_of_image_review {
    color: #e9af00;
    background-color: #f1ff6b63;
    font-weight: 700;
    padding: 6px 15px;
    margin: 5px 0;
    border-radius: 5px;
}
.main_table_ag .Pending,
.statu_of_image_processing {
    color: #ff0000;
    background-color: #ff959533;
    font-weight: 700;
    padding: 6px 15px;
    margin: 5px 0;
    border-radius: 5px;
}

.main_table_ag .ag-root-wrapper {
    border: none;
}

/* primary classes */

.layout-wrapper {
    position: relative;
}
.chatbutton {
    font-size: 45px;
    z-index: 9999;
    display: block;
    position: absolute;
}

/* chatbot css start */
.chatbot {
    position: absolute;
    bottom: 75px;
    left: 30px;
}
.chatbot-body-outer {
    position: relative;
}

.chatbot-icon {
    z-index: 99;
    position: absolute;
    /* top: 430px;
    left: 0px; */
    border-radius: 50px;
    height: 50px;
}
.chetbot-img {
    display: block;
    width: 60px;
    box-sizing: border-box;
}
.chatbot-body {
    display: block;
    background-color: #ffffff;
    width: 350px;
    border-radius: 15px !important;
    height: 510px;
    border: 1px solid rgb(218, 218, 218);
    position: absolute;
    top: -520px;
}
.chatbot-body .chatbot-header {
    background-color: #1e74d6;
    text-align: center;
    height: 150px;
    color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.chatbot-body .chatbot-header img {
    width: 120px;

    z-index: 9;
}
.chatbot-body .chatbot-header p {
    font-size: 14px;
    display: block;
    margin: 0;
}
.chatbot-body .chatbot-header span {
    font-size: 13px;
    display: block;
}
.chatbot-body .chatbot-text {
    padding: 15px 10px;
    box-sizing: border-box;
}
.chatbot-body .chatbot-text .chatbot-body-inner {
    display: block;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg {
    display: block;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-expert-msg {
    display: flex;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-expert-msg img {
    width: 100px;
    height: 25px;
    overflow: hidden;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-expert-msg .chatbot-hello-msg {
    display: block;
    margin-left: -60px;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-expert-msg .chatbot-hello-msg span {
    display: block;
    margin-bottom: -20px;
    font-size: 12px;
    color: #272424;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-expert-msg .chatbot-hello-msg p {
    display: inline-block;
    font-size: 14px;
    background-color: #ededed;
    color: #4e4c4c;
    border-radius: 8px;
    margin: -30px 0 0 0;
    padding: 5px 10px;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-msg-date {
    display: block;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-msg-date p {
    text-align: center;
    font-size: 14px;
    color: #272424;
    margin: 8px 0;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-user-msg {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex-direction: column;
    padding-left: 38px;
}
.chatbot-body .chatbot-text .chatbot-body-inner .chatbot-msg .chatbot-user-msg p {
    width: fit-content;
    margin: 3px 0;
    padding: 5px 10px;
    font-size: 14px;
    background-color: #ededed;
    color: #4e4c4c;
    border-radius: 8px;
}
.chatbot-body .chatbot-text .msg-input {
    position: relative;
    padding-bottom: 20px;
}
.chatbot-body .chatbot-text .msg-input .chatbot-input {
    display: block !important;
    width: 350px;
    height: 40px;
    padding: 8px 15px;
    font-size: 14px !important;
    background: none;
    border: none;

    border-top: 1px solid rgb(237, 237, 237);
    border-bottom: 1px solid rgb(237, 237, 237);
}
.chatbot-body .chatbot-text .msg-input .chatbot-input::placeholder {
    font-size: 14px;
}
.chatbot-body .chatbot-text .msg-input .chatbot-input:active {
    border: 1px solid gray;
}
.chatbot-body .chatbot-text .msg-input .chatbot-input-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5px;
    right: 10px;
    background-color: #0074f9;
    border: none;
    border-radius: 8px;
    display: block;
}
.chatbot-body .chatbot-text .msg-input .chatbot-input-btn .input-btn-icon {
    display: block;
    font-size: 22px;
    color: white !important;
}

/* chatbot css end */

.disable-css-cell-row {
    background: rgb(233, 236, 239);
}
.text-primary {
    color: #0bb197 !important;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #056456db !important;
    color: #fff !important;
}
.border-primary {
    border-color: #0bb197 !important;
    /* box-shadow: none; */
}

.btn-primary {
    background-color: #0bb197 !important;
    color: #fff !important;
}

/* authentication pages */

/* header & sidebar */

#page-topbar {
    background-color: #fff;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    border-bottom: 1px solid #d2d1d1;
}

.navbar-header {
    align-items: center;
    display: flex;
    height: 62px;
    justify-content: space-between;
    margin: 0 auto;
}
.navbar-brand-box {
    width: 200px;
    background-color: #1b2c3f;
    border-bottom: 2px solid #8080801f;
}
.logo {
    line-height: 63px;
}
span.logo-lg {
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo_text {
    font-size: 25px;
    font-weight: 700;
    margin-left: 5px;
    background: linear-gradient(173deg, #44f082, #86d6ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.navbar-header .header-left {
    display: flex;
    align-items: center;
}

.navbar-header .header-right {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-icon-pd {
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.anticon.anticon-bell.icon-header-notification {
    font-size: 28px;
    display: flex;
}
.pathnamecapital {
    margin: 0 12px;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
}
.pathnamecapital span.main_table_data-length {
    margin: 0 0 0 10px;
    background-color: #0bb197;
    padding: 1px 8px;
    color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 17%;
    justify-content: center;
    font-size: 14px;
}
.navbar-header .header-search-div {
    display: flex;
    align-items: center;
    margin: 0 10px;
}
.navbar-header .header-search-div .header_search_input {
    border: none;
    background-color: #f8f8f8;
    border: 1px solid #e5dfdf;
    width: 150%;
    padding: 5px 10px;
}
.navbar-header .header-search-div :where(.css-dev-only-do-not-override-14qglws).ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 10px;
    font-size: 20px;
    color: #b5b5b5;
}
.navbar-header .sidebar-togal-btn {
    border: none;
    color: #636e75;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    outline: none;
}
.navbar-header .sidebar-togal-btn svg {
    font-size: 24px;
}
/* sidemenu small start */

.main-content {
    padding: 63px 0px 0px 201px;
    background-color: #fff;
    height: 100vh;
}

.main-content-small {
    padding: 63px 0 0 110px !important;
    background-color: #fff;
    height: 100vh;
    /* position: relative;
    z-index: -99; */
}
.vertical-menu-big {
    background-color: #1b2c3f;
    bottom: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    margin-top: 0;
    position: fixed;
    top: 63px;
    width: 200px;
    z-index: 99;
}
.vertical-menu-small {
    background-color: #1b2c3f;
    bottom: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    margin-top: 0;
    position: fixed;
    top: 63px;
    width: 110px;
    z-index: 99;
}

#side-menu-small-itemp-li {
    position: relative;
}

.list-unstyled > li:first-child {
    margin-top: 0px;
}
.list-unstyled-small > li:first-child {
    margin-top: 0px;
}

ul#side-menu-item {
    height: calc(100vh - 130px) !important;
    overflow: auto;
    overscroll-behavior-x: none !important;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #11af99;
    border-color: #11af99;
    background: #ffffff;
}
a.waves-effect.active.sub-item-hover {
    color: #0bb197 !important;
}

li#side-menu-small-itemp-li:hover .sub-menu-small {
    display: block;
}
#side-menu-small-itemp-li .single-icon-small span {
    font-size: 20px;
    margin-bottom: 5px;
}
#side-menu-small-itemp-li .single-icon-small .sidebar_icon {
    font-size: 20px;
    margin-bottom: 5px;
}
#side-menu-small-itemp-li .single-icon-small {
    display: block;
    padding: 10px 0px !important;
    text-align: center;
    color: #a5b8dd;
    margin: 5px 10px;
    border-radius: 5px;
}
#side-menu-small-itemp-li .single-icon-small:hover {
    color: #0bb197;
    background-color: #7991ae2c;
}

.single-icon-small > small {
    display: block;
    font-size: 14px !important;
}
.sub-menu-small {
    z-index: 999;
    display: none;
    position: absolute;
    left: 104%;
    top: 0;
    width: 180px;
    padding: 5px 0;
    border-radius: 8px;
    background-color: #1b2c3f;
}
.sub-menu-small li {
    margin: 0 0 0 -5px;
}
.sub-menu-small::before {
    content: "";
    border-right: 8px solid #1b2c3f;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    display: block;
    position: absolute;
    left: -6px;
}
.sub-menu-small li .sub-item-hover-small {
    color: white !important;
    padding: 5px 15px;
    display: block;
    font-size: 14px !important;
}
.sub-menu-small li:hover {
    background-color: #7991ae2c;
}
.sub-menu li:hover span {
    color: #7991ae2c;
}
.navbar-brand-box-small {
    display: flex;
    justify-content: center;
    width: 110px;
    background-color: #1b2c3f;
    border-bottom: 2px solid #8080801f;
}
.navbar-brand-box-small-img {
    width: 45px;
    height: 40px;
}
.navbar-brand-box-big-img {
    width: 36px;
    height: 34px;
}
/* sidemenu small end */

.arrow-icon {
    float: right;
    border: 2px solid rgb(137, 137, 137);
    border-top: none;
    border-left: none;
    display: inline-block;
    padding: 3px;
    transition: transform 0.3s;
}

.rotate-up {
    transform: rotate(222deg);
}

.rotate-down {
    transform: rotate(45deg);
}
.icon-header-blue {
    display: block;
    border-radius: 5px;
}
.icon-header-blue > svg {
    font-size: 30px;
}
.icon-header-blue > svg > path {
    color: #0bb197 !important;
}
.side-menu-btn {
    margin-top: 8px;
}
.sub-menu {
    padding-left: 28px;
}

/* header css start  */
.navbar-header :where(.css-1xg9z9n).ant-btn {
    font-size: 14px;
    line-height: 1.5714285714285714;
    /* height: 51px; */
    padding: 0px 10px !important;
    border-radius: 6px;
}

/* header company start  */

p.mycompany-icon-btn {
    color: #636e75;
    font-size: 22px;
    line-height: 17px;
}
span.mycompany-name-text {
    font-size: 18px;
    margin: 0 5px;
}

.companylist-model button.ant-modal-close {
    display: none;
}
.ant-modal.css-14qglws.companylist-model {
    top: 0px;
    padding: 0;
    right: 0px;
    position: absolute;
}

.companylist-model span.close_icon_btn {
    display: block;
    margin-right: 10px;
    cursor: pointer;
    color: red;
    font-weight: 700;
}
.companylist-model .ant-modal-content {
    height: 100vh !important;
    padding: 0px 0px !important;
    border-radius: 0;
}
.companylist-model {
    position: absolute;
    top: 0;
    right: 0;
    width: 28% !important;
    padding: 0;
}
.companylist-model :where(.css-1xg9z9n).ant-modal .ant-modal-content {
    position: relative;
    height: 100vh;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
}

.dropdown-body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px;
    font: normal 600 18px Inter, sans-serif;
}

.dropdown-body-header .company_heading_andClose {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropdown-body-header .company_refresh_btn {
    margin: 0 0 0 5px;
    width: 23px;
    height: 23px;
    background-color: #0bb197;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    border-radius: 50%;
}
.dropdown-body-header .search_company_input {
    width: 43%;
}

a.company-select-icon {
    color: #5d5d5d;
    text-decoration: none;
    font-size: 16px;
}

li.ant-list-item:hover {
    color: #0bb197 !important;
    border: 1px solid #0bb197 !important;
}
.active .d-flex,
.active .company-select-icon {
    color: #00d5b3;
}
ul.ant-list-items {
    border-top: 1px solid rgb(0 0 0 / 38%);
    border-bottom: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.companylist-model :where(.css-1xg9z9n).ant-list .ant-list-items {
    margin: 0;
    list-style: none;
    font: normal 500 14px/20px Inter, sans-serif;
}

.companylist-model :where(.css-1xg9z9n).ant-list .ant-list-item {
    align-items: center;
    color: #383e4e;
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}
.companylist-model .active {
    background-color: #0bb19714;
    border: 1px solid #0bb197 !important;
    color: #0bb197;
}
.companylist-model .ant-modal-footer {
    display: none;
}
.companylist-model .ant-list-item {
    font-size: 20px;
    cursor: pointer;
    margin: 15px;
    border-radius: 7px;
}
.companylist-model .ant-list-item-no-flex {
    border: 1px solid #bcbfbe !important;
    color: #565656;
}
.companylist-model li.ant-list-item .company-li-div {
    display: flex;
    align-items: center;
}
.companylist-model li.ant-list-item .company-li-div span {
    font-size: 30px;
    cursor: pointer;
    margin-right: 10px;
}
.companylist-model :where(.css-dev-only-do-not-override-14qglws).ant-list-split .ant-list-item:last-child {
    border-block-end: none;
}
.companylist-model p.company-name {
    margin-left: 15px;
}

/* header company end  */

/* header admin start  */

.eliment-admin {
    display: block;
    padding: 0px 5px;
    width: 120px;
    color: #000;
}

.header-profile span {
    font-size: 16px;
    color: #636e75;
}

.header-admin-img {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid gray;
    margin: 0px 0 0 0px;
}
.header-admin-name {
    margin: 0 10px;
}
.eliment-admin span {
    text-decoration: none;
    margin-right: 5px;
}
.dropdown {
    margin: 0px !important;
    height: 70px;
    width: 130px;
    padding: 20px 0;
}
.eliment .header-sub-menu {
    border-radius: 8px;
    position: absolute;
    top: 100%;
    right: 0px;
    background-color: white;
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.eliment .sub-menu-profile {
    display: none;
    width: 180px;
}

/* ==================== */

/* header only app component css start */

.eliment .sub-menu-app {
    display: none;
    width: auto;
    border-radius: 8px;
    position: absolute;
    top: 101%;
    right: 0px;
    background-color: white;
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.eliment .sub-menu-app ul {
    padding: 10px;
}
.eliment .sub-menu-app ul .submenu-row {
    display: flex;
}
.eliment .sub-menu-app ul .submenu-row li {
    padding: 15px;
    width: 100px;
}
.eliment .sub-menu-app ul .submenu-row li:hover {
    border: 1px solid rgb(235, 233, 233) !important;
    box-sizing: border-box;
}

.eliment .sub-menu-app ul .submenu-row li a span {
    display: block;
    text-align: center;
}
.eliment .sub-menu-app ul .submenu-row li a .header-sub-menu-app-icon {
    display: block;
    font-size: 30px;
    margin-bottom: 5px;
}
.eliment:hover .sub-menu-app {
    display: block;
}

/* header app + quickcreate component css start */

.quickcreate-model ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.css-1xg9z9n {
    display: flex !important;
    flex-direction: row !important;
}
.sub-quickcreate-inner {
    margin-top: 10px;
}
.quickcreate_menu_icon {
    margin-right: 7px;
}
.quickcreate-menu {
    display: flex;
    padding: 0;
}
.sub-quickcreate-inner .sub-ul {
    width: 250px;
}
.sub-ul:first-child {
    border-right: 1px solid rgb(224, 219, 219);
}
.sub-quickcreate-inner h6 {
    /* padding-left: 12px; */
    border-bottom: 1px solid rgb(224, 219, 219);
    /* padding-bottom: 10px; */
    padding: 10px 12px;
    margin: 0;
}

.sub-quickcreate-inner .colm-flex {
    display: flex;
    height: 120px;
}
.sub-quickcreate-inner .colm {
    padding: 0 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.sub-quickcreate-inner button {
    border: 0;
    background: none;
    padding: 0;
    color: #686868;
}
.sub-quickcreate-inner p {
    margin: 0px 0px;
    padding: 4px 15px;
}
.sub-quickcreate-inner span.anticon.anticon-plus {
    margin: 0 5px 0 0;
}

/* ------------------------------ */
.eliment:hover .header-sub-menu {
    display: block;
}
.eliment .header-sub-menu ul hr {
    margin: 5px;
}
.eliment .header-sub-menu ul {
    padding: 10px 0px;
}
.eliment .header-sub-menu ul li {
    padding: 10px 30px;
}
.eliment .header-sub-menu ul li a {
    font-size: 16px;
}
.eliment .header-sub-menu li:hover {
    display: block;
    background-color: rgb(212, 210, 210);
    color: #000;
}

.eliment .header-sub-menu li:hover a {
    color: #000;
}
.header-sub-menu-profile-icon {
    font-size: 18px;
    margin-right: 10px;
}
/* header css over  */

#sidebar-menu ul li a {
    color: #a5b8dd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Work Sans, sans-serif;
    font-size: 14px;
    margin: 9px;
    border-radius: 7px;
    padding: 10px 15px;
    position: relative;
    /* transition: all 0.4s; */
    font-weight: 500;
}
#sidebar-menu ul li ul li:hover .sub-item-hover {
    color: #00d5b3;
}
#sidebar-menu ul li:hover .itemp-a {
    color: #00d5b3;
}
#sidebar-menu ul .active:hover .itemp-a {
    color: #ffffff;
}
#sidebar-menu ul li .itemp-a .sidebar_icon_title_div {
    display: flex;
    align-items: center;
}
#sidebar-menu ul li .itemp-a .sidebar_icon_title_span {
    margin-left: 12px;
}
#sidebar-menu ul > .active > a {
    background-color: #00d5b3c4;
    color: white;
}
#sidebar-menu ul li a > div > span > svg {
    font-size: 20px;
}
#sidebar-menu ul > .active > a > .arrow-icon {
    border: 2px solid #ffffff;
    border-bottom: none;
    border-right: none;
}

#sidebar-menu-small ul > .active > .icon-container-small > a {
    color: #ffffff;
    background-color: #0bb197;
}
/* bulk menu  css start */
ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.css-1xg9z9n {
    display: flex !important;
}
.bulkupload-topbar {
    display: block;
}
.bulkupload-topbar .bulkupload-topbar-inner {
    display: block;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
}
.bulkupload-topbar .bulkupload-topbar-inner::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: -1px 0px 0 0px;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul {
    box-sizing: border-box;
    margin-top: 8px;
}

.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .active a.bnk-hd-li-link {
    background-color: #f8f8f8;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #e3e3e3;
    border-bottom: 1px solid #f8f8f8;
    color: #0bb197;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li {
    display: block;
    margin: 0px 0px -2px;
    font-size: 13px;
    color: #939393;
    font-weight: 500;
    box-sizing: border-box;
}

.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li .bnk-hd-li-link {
    box-sizing: border-box;
    padding: 8px 20px;
    display: inline-block;
    font-weight: 600;
    color: #8e8e8f;
    margin-bottom: 0px;
    font-size: 12px;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li:first-child {
    margin-left: 12px;
}

.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .active:after {
    transform: scaleX(1);
}

.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li-link:hover {
    color: #0bb197 !important;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn {
    align-items: center;
    display: flex;
    position: relative;
    margin: 8px 0 8px;
    height: 30px;
    justify-content: center;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .btn-text {
    color: white !important;
}

.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .upload-btn path {
    color: white;
}
.topbar-download-btn
    ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.css-dev-only-do-not-override-14qglws {
    display: block;
}
.Download-Sample-btn {
    height: 27px !important;
    padding: 0 10px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-btn > .anticon + span,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-btn > span + .anticon {
    margin-inline-start: 6px;
    font-size: 11px;
}
.download-btn .ant-dropdown.css-dev-only-do-not-override-1xg9z9n.ant-dropdown-placement-bottomLeft {
    inset: 89.2812px auto auto 1001.83px !important;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    padding: 10px 20px;
    color: rgb(33 33 33);
    font-size: 16px;
    cursor: pointer;
}
.all-div-margin {
    margin: 0 5px;
}
.bulkupload-btn {
    border: 1px solid;
    border-radius: 4px;
    box-shadow: none;
    background: none;
    font-size: 12px;
    padding: 4px 8px;
    /* height: 26px; */
}
.topobar-icon {
    font-size: 17px;
    display: block;
    color: #000 !important;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .youtube-btn {
    color: #b80c0c;
    scale: 0.5 0.45;
    margin: 0 -10px;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .document-btn {
    color: #0c12b8;
    scale: 0.5 0.45;
    margin: 0 -10px;
}
.bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-btn .bg-btn:hover path {
    color: white;
}
.bulkupload-body {
    padding: 0px;
}

/* bulk menu css end */

.addclient {
    width: 65%;
    margin: 0 auto;
}
.addclient-form {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid rgb(198, 196, 196);
    border-radius: 5px;
    background-color: white;
    width: 100%;
}
.addclient-form .form-flax {
    margin: 0 -10px;
    display: flex;
}
.addclient-form .common {
    flex-direction: column;
    width: 100%;
    padding: 5px 10px;
}
.addclient-form .common label {
    font-size: 14px;
}
.addclient-form .common label::before {
    content: "*";
    color: red;
}
.addclient-form .common-input {
    border-radius: 4px;
    border: 1px solid #c8c8c8;
    width: 100%;
    padding: 5px 15px;
}
.addclient-form .adduser-btn {
    width: 100%;
    margin-top: 15px;
    border: none;
    background-color: #0074f9;
    border-radius: 4px;
    color: white;
    box-shadow: 1px 1px 2px 1px gray;
}
.addclient-form .adduser-btn:hover {
    background-color: #165296;
}

/* user css start */

.user-table {
    display: block;
    margin: 0 -10px;
}
.user-table table {
    width: 100%;
    padding-left: 50px;
}
.user-table table tr td:first-child {
    margin-left: 20px !important;
}
.user-table table tr th {
    padding: 8px 0 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.user-table table tr th input {
    margin-left: 5px;
    line-height: 15px;
    padding: 5px 0 0 0;
}
.user-table table tr td {
    padding: 3px 0 !important;
    font-size: 13px !important;
}
.user-table > .table tr,
.table th {
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    border-top: none !important;
}
/* user css end */

element.style {
    transform: translateY(0px);
    height: 31px !important;
}

/* Upload file component start*/
.upload-page {
    display: block;
    position: relative;
    background-color: #000000b1;
    z-index: 9999;
    margin-top: -90px;
    margin-left: -251px;
    height: 100vh;
    padding-top: 100px;
    width: 100vw;
}
.upload-page {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 65%;
}
.upl-page-heading {
    display: block;
    background-color: #dcdcdc;
    padding: 0px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.upl-page-heading p {
    margin: 0;
    padding: 10px 0;
}
.upl-page-body {
    height: auto;
}

.banking-dropdoun-in-fileupload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px 0;
}
select.banking-dropdown {
    width: 48%;
    padding: 7px 10px;
    border: 1px solid #c8c8c8;
    border-radius: 6px;
}
.bulk_upload_model .ant-modal-header {
    color: #ffffff !important;
    border-radius: 8px 8px 0 0;
    display: block;
    padding: 0;
    border-bottom: none;
    margin: -20px -24px 15px;
    padding: 15px 25px;
    border-bottom: 2px solid silver;
    background-color: #0bb197db;
}
.bulk_upload_model .ant-modal-title {
    color: #ffffff !important;
}
.bulk_upload_model :where(.css-dev-only-do-not-override-14qglws).ant-modal .ant-modal-close-x {
    color: #ffffff;
}
.bulk_upload_model button.ant-btn.css-dev-only-do-not-override-14qglws.ant-btn-default.ant-btn-outlined:hover {
    color: #0bb197;
    border-color: #0bb197;
}
.bulk_upload_model :where(.css-dev-only-do-not-override-14qglws).ant-btn-primary.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover {
    background: #0bb197c2;
}
.bulk_upload_model .ant-modal-footer :where(.css-dev-only-do-not-override-14qglws).ant-btn-primary.ant-btn-solid {
    color: #fff;
    background: #0bb197;
}
.upl-page-body .upload-file-btn {
    display: block;
}
.upl-page-body .upload-file path {
    fill: #0bb197;
}
.upl-page-body .upload-file > .guZdik {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 326px;
    max-width: 100%;
    border: 2px dashed #c8c8c8;
    height: 140px;
    padding: 44px 0px;
}
.upl-page-body .upload-file .dtlKip {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 326px;
    max-width: 100%;
    height: 140px;
    padding: 44px 0px;
}
.upl-page-body .note .note-ledger {
    margin-top: 10px;
    margin-bottom: 8px;
}
.upl-page-body .note .note-ledger > p {
    font-size: 14px !important;
}

.upl-page-body .note .note-ledger .fileTitle_text {
    font-size: 16px !important;
    margin: 10px 0;
}
.upl-page-body .note .note-ledger ul li {
    list-style-type: disc;
    margin: 0px 0 0 40px;
    padding: 0;
}
.upl-page-body .upload-form-btn {
    display: flex;
    justify-content: end;
    border-top: 1px solid rgb(231, 230, 230);
}

/* .ledger-inner-class {
  position: relative;
  display: block;
}
.ledger-inner-class .uploadfile {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
} */
/* Upload file component end*/

/* my profile component start*/
.my-profile {
    color: #3f3f3f;
    width: 70%;
    margin: 0 auto;
}
.my-profile p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}
.my-profile .profile-content.bg-white {
    border-radius: 10px;
}
.my-profile .profile-content.bg-primary {
    border-radius: 10px;
}
.my-profile .profile-content .input-padding {
    padding: 6px 15px;
    border-color: #eff2f7;
}
.my-profile .profile-content button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-primary {
    height: 39px;
    margin-top: 30px;
    width: 121px;
}
.my-profile .error-div {
    font-size: 12px;
}
/* my profile component end*/

.editable-grid-cell::after {
    content: "\2193";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: 0;
    right: 0;
    font-size: 15px;
    font-weight: 900;
    z-index: -1; /* this will set it in background so when user click onit you will get cell-click event as if user would normally click on cell */
}
