$bacground-primery-color: #eff2f7;
$border-primery-color: #d9d9d9;
$bacground-tax-link-theme-color: #0bb197;
$font-primery-color: #2ecc71;

form#singleUploadForm {
    display: inline;
}
// Auto sell form start=======================================
.autosaleModel {
    top: 20;
    width: fit-content !important;
    :where(.css-dev-only-do-not-override-14qglws).ant-checkbox-checked .ant-checkbox-inner {
        background-color: #0bb197 !important;
        border-color: #0bb197 !important;
    }
    :where(.css-dev-only-do-not-override-14qglws).ant-steps .ant-steps-item-process .ant-steps-item-icon {
        background-color: #0bb197 !important;
        border-color: #0bb197 !important;
    }
    :where(.css-dev-only-do-not-override-14qglws).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
        border-color: #0bb197 !important;
        background-color: #0bb197 !important;
    }
    .ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-14qglws).ant-radio-wrapper,
    :where(.css-dev-only-do-not-override-14qglws).ant-radio-wrapper:hover .ant-radio-inner {
        border-color: #0bb197;
    }
    .ant-modal-footer {
        display: none;
    }
    :where(.css-dev-only-do-not-override-1xg9z9n).ant-steps .ant-steps-item-process .ant-steps-item-icon {
        background-color: #0bb197;
        border-color: #0bb197;
    }
    .ant-modal-content {
        padding: 20px 20px !important;
    }
    .autosaleModel_steps {
        width: 73%;
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 10px 0;
    }
    p.autosale_model_heading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
    }

    .autosale_Body {
        display: block;
        .body_page {
            display: block;
            max-height: 400px !important;
            overflow: hidden;
            width: 674px;
            //   height: 400px;
            .page_inner {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .shadow_div {
                    padding: 15px;
                    border-radius: 10px;
                }
                .radio_btn_div {
                    display: flex;
                    justify-content: center;
                    padding: 15px 20px !important;

                    width: 100%;
                    outline: none;
                    .radio_btn_item {
                        font-size: 16px !important;
                    }
                }
                .is_Form_Element {
                    display: flex;
                    margin: 0px 0px;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    .name {
                        display: block;
                        padding: 7px 0;
                        width: -moz-fit-content;
                        width: fit-content;
                        margin: 0 10px;
                        :where(.css-dev-only-do-not-override-14qglws).ant-select-outlined:not(.ant-select-disabled):not(
                                .ant-select-customize-input
                            ):not(.ant-pagination-size-changer):hover
                            .ant-select-selector {
                            border-color: #0bb197;
                        }
                        .ant-select-focused:where(.css-dev-only-do-not-override-14qglws).ant-select-outlined:not(.ant-select-disabled):not(
                                .ant-select-customize-input
                            ):not(.ant-pagination-size-changer)
                            .ant-select-selector {
                            border-color: #0bb197;
                            outline: 0;
                        }
                        .d-block {
                            display: block !important;
                            font-size: 16px;
                            color: #787878;
                        }
                        .autosale_input_eliment {
                            padding: 8px 10px;
                            border: 1px solid #dbd7d7;
                            width: 300px;
                            border-radius: 7px;
                            font-size: 15px;
                            color: #000000;
                            outline: none;
                        }

                        .autosale_input_eliment_Select {
                            border: 1px solid #dbd7d7;
                            width: 300px;
                            border-radius: 7px;
                            font-size: 15px;
                            color: #000000;
                            outline: none;
                        }
                        .ant-select-show-search {
                            padding: 0px 0px;
                            border: 0px solid #dbd7d7;
                            width: 300px;
                            height: 40px;
                            border-radius: 7px;
                            font-size: 15px;
                            color: #000000;
                            outline: none;
                        }
                        // .ant-select.ant-select-outlined.autosale_input_eliment.css-dev-only-do-not-override-1xg9z9n.ant-select-single.ant-select-show-arrow.ant-select-show-search {
                        //   font-size: 14px;
                        //   height: 33px;
                        //   padding: 0px !important;
                        //   border: 0px solid gray !important;
                        // }
                        // .ant-select.ant-select-outlined.css-1xg9z9n.ant-select-single.ant-select-show-arrow.ant-select-show-search {
                        //   padding: 0;
                        //   width: 300px !important;
                        //   height: 40px;
                        //   border: 0px;
                        // }
                    }
                }
                .custom_item_div {
                    display: block;
                    width: 93%;
                    .custom_item_table {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .tbody {
                            height: 160px !important;
                            display: block;
                            overflow-x: hidden;
                        }
                        th,
                        td {
                            padding: 2px 6px;
                            text-align: center;
                        }

                        /* Column width distribution */
                        th:nth-child(1),
                        td:nth-child(1) {
                            width: 5%;
                        }

                        th:nth-child(2),
                        td:nth-child(2) {
                            width: 43%;
                        }

                        th:nth-child(3),
                        td:nth-child(3),
                        th:nth-child(4),
                        td:nth-child(4),
                        th:nth-child(5),
                        td:nth-child(5) {
                            width: 17%;
                        }

                        /* Additional styles for better appearance */
                        .table_item_element {
                            width: 100%;
                            padding: 2px;
                            .ant-select-selector {
                                text-align: left;
                            }
                        }

                        .table_input_element {
                            width: 100%;
                            padding: 4px 7px;
                            box-sizing: border-box;
                            outline: none;
                            border: 1px solid #dadada;
                            border-radius: 7px;
                        }

                        .tbody tr:nth-child(even) {
                            background-color: #f9f9f9;
                        }

                        .tbody tr:hover {
                            background-color: #f1f1f1;
                        }
                    }
                }
            }
        }

        .autosale_btn_div {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
            .Next_btn_div {
                display: flex;
                .btn-primary.disabled,
                .btn-primary:disabled {
                    border-color: #0bb197 !important;
                }
                .autosale_btn {
                    width: 85px;
                    display: flex;
                    justify-content: center;
                    margin-left: 10px;
                }
                .purchase_checkbox {
                    width: 168px;
                    display: flex;
                    justify-content: center;
                    margin-left: 10px;
                    margin-top: 20px;
                    align-items: center;
                    font-size: 16px;
                }
            }
        }
    }
}

// Auto sell form end=======================================
.icon-loader-model .ant-modal-content {
    background-color: #f5ebeb00;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 0%), 0 3px 6px -4px rgb(0 0 0 / 00%), 0 9px 28px 8px rgb(0 0 0 / 0%);
}

// Role management start=======================================
.splitMenu-model :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-cell,
.splitMenu-model :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > th,
.splitMenu-model :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > td {
    position: relative;
    padding: 5px 5px !important;
    font-size: 12px;
    overflow-wrap: break-word;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > th > .ant-table-wrapper:only-child .ant-table,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper:only-child
    .ant-table,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper:only-child
    .ant-table {
    margin-block: 6px !important;
    margin-inline: -4px -5px !important;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 8px;
}

button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-dashed {
    padding: 0;
    width: 35px;
    height: 35px;
    a.backto-rolemanagement {
        width: 100%;
        display: block;
        height: 100%;
        line-height: 2.4;
    }
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead > tr > td {
    background: #e0e9e8 !important;
}
// Role management end=======================================

//====================================== TransactionsTable code css start =======================================

// hiiden checkbox display none in table for remove that blank space
.ag-selection-checkbox.ag-invisible {
    display: none !important;
}
.deleteSelectedRow {
    position: absolute;
    bottom: 52px;
    left: 10px;
}

button.ant-btn.css-1xg9z9n.ant-btn-default.company-popup-card {
    border: transparent;
    background: #fff0;
    box-shadow: 0 2px 0 #00000005;
    color: #000000e0;
}
:where(.css-1xg9z9n).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #4096ff;
    border-color: #86585800;
    background: #ffffff;
}

.center-header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.TransactionsTable-table .ag-theme-balham {
    .ag-cell-value {
        // line-height: 1.3;
        word-break: normal;
    }
    :where(.css-dev-only-do-not-override-1xg9z9n).ant-btn {
        font-size: 20px;
        line-height: 1.5714285714285714;
        height: 32px;
        padding: 0px 0 0 0px;
        border-radius: 6px;
    }
    .transactions_table_ag {
        .action_btn_Div {
            display: flex;
        }
    }
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-select .ant-select-arrow {
    top: 49% !important;
}

.ag-theme-balham > .ag-ltr .ag-cell {
    border-right: 1px solid #d9dddf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2px 5px 4px 5px;
}

.ag-theme-alpine .ag-header-row {
    font-weight: 600 !important;
}
// sub table start=======================================
// quickcreate banking dropdoun start=======================================
select.banking-dropdoun {
    text-transform: none;
    width: 100%;
    margin: 5px 0px 12px;
    padding: 5px 10px;
    border-color: blue;
}
// quickcreate banking dropdoun end=======================================

// TallySyncTable   dropdoun start =======================================
.ant-modal.css-1xg9z9n.TallySyncTable-model {
    width: 704px !important;
}
.ant-modal.css-dev-only-do-not-override-1xg9z9n.TallySyncTable-model {
    width: 704px !important;
}
.TallySyncTable-model .ant-modal-content {
    padding: 0px !important;
}
.TallySyncTable-model button.ant-modal-close {
    display: none;
}
.hidden {
    display: none !important;
}

.TallySyncTable-outer {
    display: block;
    .TallySyncTable-inner {
        display: block;
        .TallySyncTable-header {
            display: block;
            border-bottom: 1px solid rgba(206, 206, 206, 1);
            padding: 10px 50px;
            background-color: rgba(249, 249, 249, 1);
            p.TallySyncTable-header-text {
                color: rgba(39, 40, 41, 1);
                font-size: 20px;
                font-weight: 500;
                padding: 0;
            }
        }
        .TallySyncTable-body {
            display: block;
            padding: 10px 50px;
            .TallySyncTable-body li {
                padding: 5px 0;
            }
            li.TallySyncTable-body-text {
                font-size: 16px;
                padding: 5px 0;
                color: rgba(94, 97, 100, 1);
                span {
                    font-weight: 500;
                }
                .anticon.anticon-check-circle {
                    font-size: 22px;
                    margin-right: 20px;
                    display: inline;
                    margin-top: 4px;
                }
                ul {
                    .TallySyncTable-body-text-sub {
                        list-style-type: circle !important;
                        margin-left: 50px;
                        span {
                            font-weight: 700;
                        }
                    }
                }
            }
            li {
                table {
                    border-collapse: collapse;
                    margin: 10px 0 !important;
                    width: 100%;
                    .TallySyncTable_header_tr {
                        background-color: rgba(22, 208, 200, 0.1);
                        color: rgba(0, 0, 0, 1);
                        // background-color: rgba(22, 208, 200, 1);
                        // color: rgba(255, 255, 255, 1);
                        height: 45px;
                        th {
                            border: 1px solid rgba(22, 208, 200, 1);
                            // border: 1px solid rgba(255, 255, 255, 1);
                            font-size: 15px;
                        }
                    }
                    .TallySyncTable_body_tr {
                        background-color: rgba(249, 249, 249, 1);
                        color: rgba(0, 0, 0, 1);
                        th {
                            border: 1px solid rgba(206, 206, 206, 1);
                        }
                    }
                    tr {
                        th:first-child {
                            width: 22%;
                        }

                        th {
                            width: 19.5%;
                            text-align: center;
                            font-weight: 500 !important;
                        }
                        td {
                            border: 1px solid rgb(199, 199, 199);
                            padding: 5px 0px;
                            text-align: center;
                            font-size: 20px;
                            font-weight: 600 !important;
                        }
                    }
                }
            }
        }
        .TallySyncTable-footer {
            display: flex;
            justify-content: start;
            padding: 8px 50px 20px;
        }
    }
}
// TallySyncTable   dropdoun end =======================================
// ErrorModel   model start =======================================
.ant-modal.css-1xg9z9n.errorMenu-model {
    width: 600px !important;
}
.ant-modal.css-1xg9z9n.errorMenu-model :where(.css-1xg9z9n).ant-modal .ant-modal-close {
    top: 14px;
}
.errorMenu-model .ant-modal-content {
    padding: 0px !important;
}
:where(.css-1xg9z9n).ant-modal .ant-modal-content {
    border-radius: 5px;
}
.errorMenu-model {
    .errorMenu-outer {
        display: block;
        .errorMenu-inner {
            display: block;
            .errorMenu-header {
                display: block;
                border-bottom: 1px solid rgb(156, 156, 156);
                p.errorMenu-model-header {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px 15px;
                }
            }
            .errorMenu-body {
                padding: 10px 15px 12px;
                display: block;
                .mismatched-line {
                    display: flex;
                    font-size: 16px;
                    font-weight: 500;
                    margin: 10px 0;
                    color: #e3727f !important;
                    p.errorMenu-body-mismatched-line {
                        margin-right: 30px;
                    }
                }
                .note-line {
                    display: block;
                    font-size: 16px;
                    p.errorMenu-body-note-line {
                        margin: 10px 0;
                        span {
                            font-weight: 600;
                        }
                    }
                    p.errorMenu-body-note-line.errorMenu-note {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
// ErrorModel   model end =======================================

// transition table ledger dropdoun end=======================================

.ant-modal.css-dev-only-do-not-override-1xg9z9n.splitMenu-model .ant-modal-content {
    padding: 0 !important;
}
.splitMenu-model.ant-modal.css-dev-only-do-not-override-1xg9z9n.splitMenu-model {
    width: 600px !important;
}
.ant-modal.css-1xg9z9n.splitMenu-model {
    width: 600px !important;
}
.splitMenu-model td.ant-table-cell {
    line-height: 1.5 !important;
}

.splitMenu-model .ant-modal-content {
    padding: 0 !important;
}
.splitMenu-outer {
    .splitMenu-inner {
        .splitMenu-header {
            padding: 12px 15px 0;
            p {
                font-size: 14px;
                letter-spacing: -1px;
                padding: 0px 33px 0px 0px;
            }
        }
        .splitMenu-header:after {
            background-color: #d9d9d9;
            content: "";
            display: block;
            height: 1px;
            margin-left: -14px;
            margin-top: 8px;
            width: 104%;
        }
        .splitMenu-body {
            .body-split-Topbar {
                padding: 15px;
                .Topbar-Amount {
                    font-size: 16px;
                    font-weight: 500;

                    .Topbar-Remaining {
                        color: #3672f7;
                    }
                }
                .Topbar-input {
                    font-size: 16px;
                    padding: 7px 0 0 0;
                    display: flex;
                    align-items: end;
                    .Topbar-input-SelectLedger {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                    }
                    input.ant-input.css-dev-only-do-not-override-1xg9z9n.ant-input-outlined {
                        margin: 5px 0 0 0;
                    }
                }
            }
            :where(.css-1xg9z9n).ant-table-wrapper,
            :where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper {
                clear: both;
                max-width: 100%;
                padding: 0 15px 15px;
            }

            :where(.css-14qglws).ant-table-wrapper .ant-table-cell,
            :where(.css-14qglws).ant-table-wrapper .ant-table-thead > tr > th,
            :where(.css-14qglws).ant-table-wrapper .ant-table-tbody > tr > th,
            :where(.css-14qglws).ant-table-wrapper .ant-table-tbody > tr > td,
            :where(.css-14qglws).ant-table-wrapper tfoot > tr > th,
            :where(.css-14qglws).ant-table-wrapper tfoot > tr > td {
                position: relative;
                padding: 1px 7px;
                overflow-wrap: break-word;
            }
        }
        .splitMenu-footer {
            display: flex;
            justify-content: space-between;
            padding: 0 15px 15px;
            span {
                button.ant-btn.css-dev-only-do-not-override-14qglws.ant-btn-primary.ant-btn-solid.split-add-btn {
                    margin-left: 10px;
                }
            }
        }
    }
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-space-gap-col-small {
    column-gap: 0px !important;
}
.ant-space.css-1xg9z9n.ant-space-horizontal.ant-space-align-center.ant-space-gap-row-small.ant-space-gap-col-small {
    padding: 0 5px;
}

.TransactionsTable {
    display: block;

    .ag-header-cell.header-center .ag-header-cell-label {
        justify-content: center;
    }
    .TransactionsTable-heading {
        background-color: $bacground-primery-color;
        display: flex;
        justify-content: space-between;
        padding: 15px 15px;
        .table-title {
            display: flex;
            justify-content: space-between;

            button {
                margin: auto 10px auto 0px;
                width: 25px;
                height: 25px;
                font-weight: 600;
                font-size: 14px;
                display: flex;
                justify-content: center;
                span.anticon.anticon-arrow-left {
                    display: block;
                }
            }
            p {
                margin: auto 0;
                font-size: 17px;
            }
            .TransactionsNumber {
                margin: auto 0 auto 5px;
                background-color: $bacground-tax-link-theme-color;
                border-radius: 100%;
                color: white;
                font-size: 14px;
                font-weight: 500;
                padding: 2px 6px;
            }
            small {
                margin: auto 0 auto 5px;
                font-size: 18px;
            }
        }
        .table-company-title {
            display: flex;
            margin: auto 0;
            align-items: end;
            span {
                font-size: 16px;
                margin-right: 5px;
                margin-bottom: 2px;
            }
            p {
                font-size: 20px;
                color: $bacground-tax-link-theme-color;
            }
        }
    }
    .TransactionsTable-top-heading {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px 12px;
        height: 120px;
        .operations {
            width: 25%;
            .operations-inner {
                align-items: center;
                p {
                    font-size: 18px;
                    font-weight: 500;
                    display: inline-block;
                }
                small {
                    font-size: 12px;
                    margin-left: 5px;
                    display: inline-block;
                }
            }
            span {
                font-size: 14px;
                display: block;
                margin: 8px 0;
            }
            input {
                font-size: 14px;
            }
        }
        .filters {
            display: block;
            width: 50%;
            p {
                font-size: 14px;
                font-weight: 400;
                display: inline-block;
            }
            .filters-checkbox {
                display: flex;
                margin: 12px 0;
                .checkbox {
                    margin-right: 1%;
                    display: flex;
                    input {
                        width: 15px !important;
                        height: 17px !important;
                    }
                    label {
                        margin: 0 0 0 7px;
                        font-size: 12px;
                    }
                }
            }
            .filters-date {
                display: flex;
                align-items: center;
                .date-filter {
                    margin-right: 57px;
                    :where(.css-dev-only-do-not-override-14qglws).ant-picker-outlined:hover {
                        border-color: #0bb197;
                        background-color: #ffffff;
                    }
                }
                .creditdebit {
                    display: block;
                    .Rs-text {
                        margin-right: 10px;
                        margin-left: 30px;
                    }
                    p.credit-amount {
                        color: #00a900;
                    }
                    p.debit-amount {
                        color: red;
                    }
                }
            }
            .date-creditdebit {
                font-size: 14px;
                display: flex;
                .date {
                    .ant-picker.ant-picker-range.ant-picker-outlined.css-1xg9z9n.RangePicker {
                        padding: 0px 11px 0px !important;
                    }
                    button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-default.remove-filter-btn {
                        height: 26px !important;
                        width: 26px !important;
                        margin-left: 10px !important;
                        padding: 1px 3px !important;
                    }
                    button.ant-btn.css-1xg9z9n.ant-btn-default.remove-filter-btn {
                        margin-left: 5px;
                        width: 26px;
                        height: 24px;
                        margin-top: 0px;
                        padding: 0px 0px;
                    }
                }
                .creditdebit {
                    display: flex;
                    align-items: center;
                    margin-left: 4%;

                    .amount {
                        margin: 0 15px 0 5px;
                    }
                    .credit-Rs {
                        color: #49ed49;
                    }
                    .debit-Rs {
                        color: #ff0000;
                    }
                }
            }
        }
        .controal-btn {
            display: flex;
            flex-direction: column;
            align-items: end;
            width: 25%;
            position: relative;
            margin: -5px 0;

            .controal-btn-line {
                display: flex;
                justify-content: flex-end;
                align-items: end;
                margin: 0 0 11px 0;
                .all-btn {
                    :where(.css-dev-only-do-not-override-14qglws).ant-btn-primary.ant-btn-solid {
                        color: #fff;
                        background: #0bb197;
                    }
                    button {
                        width: fit-content !important;
                        height: 32px;
                        margin-left: 15px;
                    }
                    :where(.css-dev-only-do-not-override-14qglws).ant-btn-default.ant-btn-outlined:not(:disabled):not(
                            .ant-btn-disabled
                        ):hover,
                    :where(.css-dev-only-do-not-override-14qglws).ant-btn-default.ant-btn-dashed:not(:disabled):not(
                            .ant-btn-disabled
                        ):hover {
                        color: #0bb197;
                        border-color: #0bb197;
                        background: #ffffff;
                    }
                    :where(.css-dev-only-do-not-override-1xg9z9n).ant-btn > .anticon + span,
                    :where(.css-dev-only-do-not-override-1xg9z9n).ant-btn > span + .anticon {
                        margin-inline-start: 6px;
                        font-size: 14px;
                    }
                }
                .save-btn-menu {
                    button.ant-btn.css-dev-only-do-not-override-1xg9z9n.ant-btn-default {
                        margin-top: 8px;
                    }
                }
                button.bulkupload-topbar-btn.bulkupload-btn.btn-outline-danger.bg-btn {
                    height: 30px;
                    span.anticon.anticon-youtube.topobar-icon.text-danger {
                        color: #dc3545 !important;
                    }
                    span.anticon.anticon-youtube.topobar-icon.text-danger:hover svg {
                        color: #fff;
                    }
                }
            }
            .transactionstable-sidemeny-btn {
                width: 25px;
                height: 30px;
                position: absolute;
                right: -15px;
                span.anticon.anticon-double-left {
                    margin-left: -6px;
                }
            }
        }
    }
    span.anticon.anticon-menu-unfold.split-icon {
        font-size: 18px;
    }
    span.anticon.anticon-delete.delete-icon {
        font-size: 18px;
    }

    .TransactionsTable-delete-btn {
        position: absolute;
        bottom: 8px;
        left: 12px;
        padding: 0 15px;
    }
}

.ag-main-table {
    height: 100% !important;

    :where(.css-14qglws).ant-btn {
        font-size: 18px;
        line-height: 1.5714285714285714;
        padding: 0px 5px;
        border-radius: 6px;
    }
    .hide_btn_class {
        font-size: 18px;
        display: none;
    }
    .action_dropdown_icon {
        width: 31px;
        height: 30px;
        border-radius: 15px !important;
    }
    .action_dropdown_icon:hover {
        background-color: rgb(24 144 255 / 20%);
    }
}
@media (min-width: 1100px) {
    .ag-main-table {
        .action_dropdown_icon {
            display: none;
        }
        .hide_btn_class {
            display: block;
        }
    }
}

// settings - my plan=============================
.my-plan {
    display: block;
    padding: 0 15px;
    .my-plan-ul {
        display: block;

        .my-plan-li {
            border-bottom: 1px solid #e1e1e1;
            margin: 0 -15px;
            padding: 8px 15px;
            .show-btn {
                color: $bacground-tax-link-theme-color;
                svg {
                    font-size: 12px;
                    margin-left: 4px;
                }
            }
            .show-component {
                display: none;
                .myplan-sub-ul {
                    display: flex;
                    margin: 0 100px;
                    .myplan-sub-li {
                        border: 1px solid #0bb197;
                        border-radius: 10px;
                        width: 33.33%;
                        margin: 10px 20px;
                        padding: 20px 10px;
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

                        .plan-one {
                            display: block;
                            sup {
                                top: -1.5em;
                                font-size: 20px;
                                color: $bacground-tax-link-theme-color;
                            }
                            .price {
                                display: inline;
                                p {
                                    font-size: 40px;
                                    display: inline;
                                    color: $bacground-tax-link-theme-color;
                                    margin-left: -13px;
                                }
                                sub {
                                    font-size: 13px;
                                }
                            }
                            .count {
                                p {
                                    margin: 10px 0;
                                    font-size: 14px;
                                    display: inline;
                                }
                                button#btn {
                                    border-radius: 30px;
                                    width: 20px;
                                    height: 20px;
                                    line-height: 0;
                                    padding-left: 5px;
                                }
                                small {
                                    margin: 0 5px;
                                    font-size: 16px;
                                }
                            }
                            .firstPlanval {
                                color: $bacground-tax-link-theme-color;
                                font-size: 14px;
                                margin: 10px 0;
                            }
                            .showmore-btn {
                                color: $bacground-tax-link-theme-color;
                            }

                            .showmore-ul {
                                display: none;
                                list-style-type: square !important;
                                color: black;
                                li {
                                    width: 100%;
                                    margin: 0;
                                    padding: 0;
                                    border: none;
                                    display: block;
                                    font-size: 14px;
                                    margin: 6px 0 6px 35px;
                                }
                            }

                            .showmore-ul.show {
                                display: block;
                            }
                        }
                    }
                }
            }
            .show-component.show {
                display: block;
                padding: 5px 0 12px;
            }
        }
    }
}
//============ mapping css start =========================
.mapping-outer {
    display: block;
    .mapping-inner {
        display: block;
        .mapping-heading-outer {
            border-bottom: 2px solid $bacground-primery-color;
            height: 10%;
            .mapping-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 10px;
                height: 70px;
                .beck-btn {
                    display: flex;
                    Button {
                        width: 25px;
                        height: 25px;
                        font-weight: 600;
                        font-size: 14px;
                        padding: 0 5px 0 9px;
                        span.anticon.anticon-arrow-left {
                            margin-left: -5px;
                        }
                    }
                    p {
                        svg {
                            width: 20px;
                            height: 20px;

                            margin: 2px 0 0 6px;
                        }
                    }
                }
                .Steps-div {
                    width: 550px;
                }

                .invoice-btn {
                    display: flex;
                    align-items: center;
                    .invoice-common {
                        margin: 0 0 0 10px;
                    }
                    .Configration-btn {
                        margin: 0 20px;
                        width: fit-content;
                        span {
                            font-size: 14px;
                        }
                    }
                    .mapping-ytb-btn {
                        border-radius: 5px;
                    }
                    .mapping-ytb-btn:hover svg {
                        color: white;
                    }
                }
            }
        }
        .mapping-body-outer {
            margin: 15px 0px;
            height: calc(100vh - 140px);
            overflow-x: hidden;
            .export-toggle-container {
                display: flex;
                align-items: center;
                margin: 0 15px;
                button.ant-switch.export-toggal-switch.css-dev-only-do-not-override-14qglws {
                    margin: 0 10px 0px;
                }
            }
            .excel-confirm {
                display: flex;
                margin: 0 15px;
                padding: 5px 0;
                .excel-confirm-btn {
                    margin-right: 45px;
                }
            }
            .mapping-body {
                display: flex;
                margin: 15px 0px 0;
                // height: 640px;
                .maped-box-w-100 {
                    width: 100% !important;
                }
                .maped-box-commun {
                    width: 50%;
                    box-shadow: none;
                    padding: 0 15px;
                    .box-heding {
                        display: flex;
                        align-items: center;

                        p {
                            font-size: 22px;
                            font-weight: 600;
                        }
                        span {
                            color: white;
                            background-color: #1c00f1;
                            margin-left: 10px;
                            padding: 0px 6px;
                            height: 25px;
                            line-height: 25px;
                            border-radius: 30px;
                        }
                    }
                    .table {
                        border: 2px solid rgb(159, 158, 158);
                        border-radius: 5px;
                        margin: 10px 0 0 0;
                        // height: 550px;
                        // overflow: auto;
                        table {
                            display: flex;
                            flex-direction: column;

                            tr {
                                display: flex;
                                width: 100%;
                                th:first-child,
                                td:first-child {
                                    width: 26%;
                                }
                                th {
                                    background-color: $bacground-primery-color;
                                }
                                th,
                                td {
                                    width: 44%;
                                    font-size: 12px;
                                }
                                td {
                                    select#mappingColumn {
                                        width: 95%;
                                        padding: 8px 6px;
                                        border-radius: 5px;
                                        position: relative;
                                        border-color: $bacground-primery-color;
                                        .remove-heading-btn {
                                            position: absolute;
                                            left: 20px;
                                            background-color: #dc3545 !important;
                                            color: rgb(170, 35, 35) !important;
                                        }
                                    }
                                }
                                th:last-child,
                                td:last-child {
                                    width: 30%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mapping-footer-outer {
            display: block;
            border-top: 2px solid $bacground-primery-color;
            .mapping-footer {
                display: flex;
                justify-content: end;
                margin: 0 15px;
                .mapping-btn {
                    margin-left: 15px;
                }
            }
        }
    }
}
//============ mapping css end =========================
//============ Users css start =========================
.user-heading {
    background-color: #f8f8f8;
    height: 100%;
    .user-heading-ul {
        display: flex;

        margin: 0 0px;
        .user-heading-li {
            box-sizing: border-box;
            padding: 7px 15px;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
            .user-heading-li-a {
                font-size: 12px;
                letter-spacing: 1px;
                color: #8e8e8f;
            }
        }
        .active a {
            color: #0bb197 !important;
            font-size: 12px;
            letter-spacing: 1px;
        }
    }
}

.Users_Div {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
    background-color: white;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    .Users_Table {
        th.ant-table-cell {
            padding: 10px;
        }
        td.ant-table-cell {
            padding: 5px;
        }
    }
}
//============ Users css end =========================
//============ Role_Management css end =========================
.Role_Management_Div {
    .Role_Management_heading {
        border: 1px solid #d3d1d1;
        margin: 15px;
        padding: 15px;
        border-radius: 10px;
        display: block;
        background-color: white;
    }
    .Role_Management_Table {
        border: 1px solid #d3d1d1;
        margin: 15px;

        border-radius: 10px;
        display: block;
        background-color: white;
    }
}

//============ Role_Management css end =========================
/* MyProfileForm component start*/
.my_profile {
    width: 60%;
    margin: 15px auto 0;

    .profile_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile_heading_p {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 1.3px;
        }
    }

    form.my_profile_form {
        display: block;
        .profile_content_bg {
            background-color: white;
            border: 1px solid #e3e3e3;
        }
        .profile_content {
            display: block;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            .profile_body {
                display: flex;
                flex-direction: row;
                margin: 15px 0;
                .name {
                    width: 50%;
                    padding: 0 15px;
                    display: flex;
                    flex-direction: column;
                    .email_mo_div {
                        display: flex;
                        align-items: self-end;
                        justify-content: space-between;
                        .email_mo_update_btn {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-size: 13px;
                            color: #0bb197;
                            cursor: pointer;
                            .email_mo_update_btn_icon {
                                width: 14px;
                                margin-right: 2px;
                            }
                        }
                    }
                    label.my_profile_label {
                        font-size: 16px;
                    }
                    .input_padding {
                        padding: 5px 10px;
                        border-radius: 5px;
                        margin-top: 10px;
                        font-size: 15px;
                        outline: none;
                        cursor: pointer;
                    }

                    .input_Profile_Logo_padding {
                        padding: 5px 10px;
                        border-radius: 5px;
                        border: 1px solid #c0bebe;
                        margin-top: 10px;
                        font-size: 15px;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
            .my_profile_div {
                display: flex;
                justify-content: center;
                .my_profile_btn {
                    color: #fff;
                    background-color: #0bb197;
                }
            }
        }
    }
}

/* MyProfileForm component end*/

/* header notification start  */

/* header notification end  */

/*  AutoExpensesForm start  */
.custom-table .ant-table-cell {
    padding: 5px !important; /* Set padding for all cells, including header */
}

.custom-table .ant-table-thead > tr > th {
    padding: 5px !important; /* Set padding for header cells */
}
.autoExpensesForm-modal {
    input.ant-input.css-dev-only-do-not-override-14qglws.ant-input-outlined.expense_select_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        margin: 0 auto;
        font-size: 25px;
        background-color: #0bb197;
        outline: none;
    }
}
.auto_expence_party_name_div {
    display: flex;
    justify-content: center;
    align-items: center;
    label.auto_expence_party_name {
        font-size: 22px;
        margin-right: 20px;
    }
    .ant-select.ant-select-outlined.auto_expence_party_name_input_eliment_Select.css-dev-only-do-not-override-14qglws.ant-select-single.ant-select-show-arrow.ant-select-show-search {
        width: 400px;
    }
    :where(.css-14qglws).ant-select-single {
        font-size: 14px;
        height: 32px;
        width: 400px;
    }
}

/*  AutoExpensesForm end  */
/*  PaymentPage start  */

.checkout_div {
    display: block;
    background-color: #f7f7f7;
    height: 100vh;
    .checkout_div_inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: calc(100vh - 20vh);
        height: 90vh;
        .plan_div_heading {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;
            .home_page_btn {
                outline: none !important;
                box-shadow: none;
                border: 1px solid gray;
                background-color: #022b50;
                padding: 15px 50px;
                border-radius: 16px;
                margin-top: 25px;
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
            }
            p.heading_title {
                color: #022b50;
                font-size: 42px;
                font-weight: 700;
            }
            span.heading_details {
                font-size: 18px;
                color: #838383;
                text-align: center;
                width: 60%;
            }
        }
        .payment_container_div {
            display: block;
            margin: 25px 0;
            .body_div {
                display: flex;
                justify-content: center;
                .checkout_card {
                    background-color: #ffffff;
                    padding: 35px 50px;
                    border-radius: 12px;
                    width: 30%;
                    max-width: 700px;
                    margin: 0px 15px;
                    p.card_title {
                        font-size: 26px;
                        font-weight: 600;
                        padding-bottom: 5px;
                        color: #022b50;
                    }
                }
                .billing_details_div {
                    display: block;
                    p.card_title {
                        border-bottom: 1px solid rgba(165, 165, 165, 0.18);
                        margin-bottom: 15px;
                    }
                    .input_div_flax {
                        display: flex;
                        margin: 10px -10px;

                        .input_div {
                            display: block;
                            width: 100%;
                            margin: 5px 10px 15px;
                            label {
                                display: inline-block;
                                margin-bottom: 5px;
                            }
                            input {
                                padding: 8px 10px;
                                border-radius: 7px;
                                background: #faf9fc;
                                border: 2px solid #ddd;
                                /* margin-top: 5px; */
                                font-size: 15px;
                                width: 100%;
                                outline: none;
                            }
                            input::placeholder {
                                color: #bababa;
                            }
                        }
                    }
                    .input_div {
                        display: block;
                        width: 100%;
                        padding: 5px 0px;
                        margin: 0px 0;
                        label {
                            display: inline-block;
                            margin-bottom: 5px;
                        }
                        select {
                            padding: 8px 10px;
                            border-radius: 5px;
                            background: #faf9fc;
                            border: 2px solid #ddd;
                            /* margin-top: 5px; */
                            font-size: 14px;
                            width: 100%;
                            outline: none;
                        }
                        select::placeholder {
                            color: #bababa;
                        }
                        input {
                            padding: 8px 10px;
                            border-radius: 7px;
                            background: #faf9fc;
                            border: 2px solid #ddd;
                            margin-top: 0px;
                            font-size: 14px;
                            width: 100%;
                            outline: none;
                        }
                        input::placeholder {
                            color: #bababa;
                        }
                    }
                }
                .your_order_div {
                    display: block;
                    .product_div {
                        background: #faf9fc;
                        border: 2px solid #ddd;
                        font-size: 14px;
                        padding: 10px 15px;
                        border-radius: 10px;
                        .item_div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 10px 0;
                            p.dark_plan_text {
                                font-weight: 700;
                            }
                        }
                        .item_dark_div {
                            border-bottom: 1px solid rgba(165, 165, 165, 0.18);

                            p {
                                font-weight: 700;
                            }
                        }
                        .item_btn_div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin: 10px 0 0px 0;

                            input {
                                padding: 8px 10px;
                                font-size: 14px;
                                width: 70%;
                                border-radius: 5px;
                                border: 2px solid #ddd;
                                outline: none;
                            }
                            button {
                                padding: 8px 15px;
                                font-size: 14px;
                                background-color: #022b50;
                                color: white;
                                border: 2px solid #fff;
                                border-radius: 5px;
                                margin-left: 10px;
                                width: 30%;
                            }
                        }
                    }
                    span {
                        display: block;
                        padding: 15px 18px;
                        line-height: 16px;
                        font-size: 12px;
                    }
                    button {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 10px 0px;
                        border-radius: 7px;
                        font-size: 14px;
                        background-color: #022b50;
                        color: white;
                        font-weight: 700;
                        border: none;
                    }
                }
            }
        }
        .footer_div {
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: #000;
                padding: 0;
            }
        }
    }
}

.plan_div {
    display: block;
    .plan_div_inner {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .plan_div_body {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 25px 0;
            .plan_div_body_inner {
                display: flex;
                justify-content: center;
                width: 100%;
                .body_left_card {
                    width: 25%;
                    max-width: 500px;
                    background-color: white;
                    color: #022b50;
                }
                .body_right_card {
                    width: 40%;
                    max-width: 800px;
                    background-color: #022b50;
                    color: #ffffff;
                }
                .card {
                    display: block;
                    padding: 25px 25px;
                    margin: 0 15px;
                    border-radius: 15px;
                    p.card_title {
                        font-size: 26px;
                        font-weight: 600;
                        font-family: Poppins;
                    }
                    .card_title_div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span.card_title_RECOMMENDED {
                            background-color: #ffffff26;
                            padding: 8px 15px;
                            font-size: 12px;
                            border-radius: 20px;
                        }
                    }
                    .card_feature {
                        ul {
                            height: 185px;
                            padding: 10px 0;
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;
                            li {
                                list-style-type: inherit;
                                margin: 0 0 0 30px;
                                padding: 10px 0;
                                font-size: 14px;
                            }
                        }
                    }
                    .card_btn_div {
                        display: flex;
                        justify-content: center;
                        .card_btn {
                            font-size: 14px;
                            padding: 10px 50px;
                            border-radius: 26px;
                        }
                        button.left_card_btn {
                            background-color: #022b50;
                            color: #ffffff;
                        }
                        button.right_card_btn {
                            background-color: #ffffff;
                            color: #022b50;
                        }
                    }
                }
            }
        }
    }
}
// Login page start
.bg-overlay {
    background-image: url("../../src/assets/images/auth-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.auth-bg {
    background-color: #000000a7;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .auth-page {
        background-color: #fff;
        font-size: 14px;
        border-radius: 12px;
        padding: 1.5rem;
        text-align: center;
        .welcome_text {
            font-size: 18px;
        }
        .text-muted {
            color: #6c757d !important;
        }
        .titel {
            display: flex;
            justify-content: center;
            align-items: center;
            .titel_text {
                font-size: 36px;
                font-weight: 700;
                margin-left: 5px;
                background: linear-gradient(173deg, #44f082, #86d6ff);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .text_cursor_pointer {
            cursor: pointer;
        }
        div#container {
            padding: 2px 40px;
        }
        .GoogleLogin_div {
            width: 100%;
            max-width: 400px;
            display: flex !important;
            flex-direction: column;
            align-items: center !important;
            justify-self: center !important;
            margin: 15px 0;
            .GoogleLogin button {
                max-width: 300px;
                width: 100%;
            }
        }
        .separator {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 15px 0;
            color: #666;
            font-size: 14px;
            span {
                white-space: nowrap;
                margin: 0 15px;
                font-size: 18px;
            }
        }
        .separator::before,
        .separator::after {
            content: "";
            flex: 1;
            border-top: 1px solid #ccc;
        }
        form {
            font-size: 14px;
            min-width: 350px;
            input,
            button {
                font-size: 14px !important;
            }
            label {
                font-weight: 600;
            }
            .login_page_input_div {
                margin: 15px 0;
            }
            .forgot_div {
                display: flex;
                justify-content: space-between;
                margin: 15px 0 0px;
            }
            .log_in_btn {
                width: 100%;
                margin: 15px 0;
            }
        }
    }
}

// Login page end

/*  PaymentPage end  */

@media (max-width: 1024px) {
    .chatbutton {
        .chatbot {
            bottom: 55px;
            left: 45px;
            .chetbot-img {
                width: 46px;
            }
        }
    }
    .navbar-header {
        height: 46px;
        .header-search-div .header_search_input {
            width: 125%;
            padding: 3px 10px;
            background-color: #f8f8f8;
            border: 1px solid #d7d5d5;
            font-size: 12px;
            .ant-input-prefix {
                margin-inline-end: 10px;
                font-size: 14px;
                color: #b5b5b5;
            }
        }
        .header-icon-pd {
            padding: 0 7px 0px 7px;
        }
        .sidebar-togal-div {
            margin-left: 7px;
        }
        .navbar-brand-box {
            width: 160px;
            .logo {
                line-height: 45px;
                .navbar-brand-box-small-img {
                    width: 36px;
                    height: 30px;
                }
            }
        }
        .navbar-brand-box-small {
            width: 90px;

            .logo {
                line-height: 45px;
                .navbar-brand-box-small-img {
                    width: 36px;
                    height: 30px;
                }
            }
        }

        .sidebar-togal-btn svg {
            font-size: 18px;
        }

        .pathnamecapital {
            margin: 0 7px;
            font-size: 14px;
            .pathnamecapital span.main_table_data-length {
                margin: 0 0 0 7px;
                padding: 0px 6px;
                font-size: 12px;
            }
        }
        .header-right {
            .icon-header-blue > svg {
                font-size: 20px;
            }
            .anticon.anticon-bell.icon-header-notification {
                font-size: 18px;
            }
            .mycompany-name-text {
                font-size: 14px;
                margin: 0 5px;
            }
            p.mycompany-icon-btn {
                font-size: 16px;
            }
            .header-admin-img {
                width: 28px;
                height: 28px;
            }
            .header-profile span {
                font-size: 12px;
            }
        }
    }
    .vertical-menu-small {
        top: 46px;
        width: 90px;
        #side-menu-small-itemp-li {
            padding: 0px 0px !important;
            margin: 6px 0px;
            .sub-menu-small {
                left: 106%;
                width: 100px;
                .sub-item-hover-small {
                    padding: 5px 14px;
                    font-size: 12px !important;
                }
            }
            .single-icon-small {
                padding: 8px 3px !important;
                margin: 5px 5px;
            }
            .sidebar_icon {
                font-size: 16px;
                margin-bottom: 3px;
            }
            .single-icon-small > small {
                display: block;
                font-size: 11px !important;
            }
        }
    }
    .vertical-menu-big {
        top: 46px;
        width: 160px;
        #sidebar-menu ul li a {
            margin: 5px;
            padding: 6px 7px;
            font-size: 12px;
            div > span > svg {
                font-size: 15px;
            }
        }
        #sidebar-menu ul li .itemp-a .sidebar_icon_title_span {
            margin-left: 8px;
        }
        .navbar-brand-box-big-img {
            width: 30px;
            height: 28px;
        }
        .logo_text {
            font-size: 20px;
        }
    }
    .main-content {
        padding: 46px 0px 0px 161px;
    }
    .main-content-small {
        padding: 46px 0 0 90px !important;
    }
    .main-content-small,
    .main-content {
        .bulkupload-topbar {
            .bulkupload-topbar-inner {
                .bulkupload-topbar-header {
                    .bulkupload-topbar-header-ul {
                        margin-top: 8px !important;
                        .bnk-hd-li:first-child {
                            margin-left: 5px;
                        }
                        .bnk-hd-li .bnk-hd-li-link {
                            padding: 5px 15px;
                            font-size: 11px;
                        }
                        .active {
                            .bnk-hd-li-link {
                                padding: 5px 15px;
                                font-size: 11px;
                            }
                        }
                    }
                    .bulkupload-topbar-header-btn {
                        margin: 7px 0 7px;
                        .Download-Sample-btn {
                            height: 20px !important;
                            padding: 0 7px;
                        }
                        :where(.css-dev-only-do-not-override-14qglws).ant-btn > span {
                            font-size: 12px;
                        }
                        .bulkupload-btn {
                            font-size: 11px;
                            padding: 0px 8px;
                            height: 20px;
                        }
                    }
                }
            }
            .bulkupload-topbar-inner::after {
                margin: 0px 0px 0 0px;
            }
        }
        .page-content {
            padding: 0 0 38px;
        }
    }

    // Login page 1024px start

    .auth-bg {
        .auth-page {
            font-size: 12px;
            display: block;
            width: 300px;

            .welcome_text {
                font-size: 16px;
            }
            .titel {
                img {
                    width: 36px;
                }
                .titel_text {
                    font-size: 26px;
                }
            }
            div#container {
                padding: 2px 40px;
            }
            .GoogleLogin_div {
                width: 100%;
                max-width: 400px;
                margin: 0 auto;
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                iframe#gsi_269658_438372,
                iframe#gsi_656432_986259 {
                    width: 176px !important;
                }
                .GoogleLogin button {
                    max-width: 300px;
                    width: 100%;
                }
            }

            .separator {
                margin: 5px 0;
                font-size: 14px;
            }
            .login_page_alert_div {
                padding: 10px;
                font-size: 12px;
            }
            form {
                min-width: 250px;

                .form-control {
                    width: 250px;
                    padding: 3px 10px;
                }
                label {
                    font-size: 12px;
                    margin-bottom: 5px;
                }
                .login_page_input_div {
                    margin: 5px 0;
                }
                .forgot_div {
                    margin: 10px 0 0px;
                    font-size: 12px;
                }
                .log_in_btn {
                    margin: 10px 0;
                    padding: 4px 10px;
                    font-size: 14px !important;
                }
            }
        }
    }
    // Login page 1024px end
    // MyCompany NotificationDropdown page 1024px start
    .companylist-model {
        width: 33% !important;
        .company-dropdown-outer {
            .dropdown-body-header {
                padding: 7px 7px 7px;
                .company_heading_andClose {
                    font-size: 10px;
                    span.close_icon_btn {
                        margin-right: 5px;
                    }
                    .company_refresh_btn {
                        margin: 0 0 0 2px;
                        width: 12px;
                        height: 12px;
                        font-size: 8px;
                    }
                }

                .search_company_input {
                    width: 38%;
                    padding: 0px 4px;
                    font-size: 10px;
                }
                .company_manage_btn {
                    font-size: 10px;
                    padding: 0px 5px;
                    border-radius: 4px;
                    height: 19px;
                }
            }
            .ant-list-item {
                font-size: 12px;
                margin: 5px;
                padding: 7px 7px;
                .company-li-div {
                    span {
                        font-size: 15px;
                        margin-right: 5px;
                    }
                    a.company-select-icon {
                        font-size: 10px;
                    }
                }
            }
            .notifications_body_div {
                .notifications_type_div {
                    height: 27px;
                    .notifications_type_btn {
                        padding: 0 10px;
                        font-size: 10px;
                    }
                    .activetype {
                        border-bottom: 2px solid #65d3c2;
                        margin-bottom: -6px;
                        padding-bottom: 5px;
                    }
                }
                .notifications_list .notifications_list_inner li.noty_li {
                    padding: 0px 10px;
                    height: 44px;
                    span.noty_time {
                        font-size: 10px;
                    }
                    p.noty_text {
                        font-size: 10px;
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    // MyCompany NotificationDropdown page 1024px end
}

@media (min-width: 1024px) and (max-width: 1920px) {
    .bulkupload-topbar .bulkupload-topbar-inner .bulkupload-topbar-header .bulkupload-topbar-header-ul .bnk-hd-li .bnk-hd-li-link {
        font-size: 13px;
    }
    .single-icon-small > small {
        font-size: 14px !important;
    }
    #sidebar-menu ul li .itemp-a .sidebar_icon_title_span {
        margin-left: 12px;
        font-size: 16px;
    }
}

// NotificationDropdown start
.notifications_body_div {
    .notifications_type_div {
        height: 45px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px 0 1px 0 solid gray;
        border-top: 1px solid rgb(0 0 0 / 13%);
        border-bottom: 1px solid rgb(0 0 0 / 13%);
        .notifications_type_btn {
            padding: 0 15px;
            color: black;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }
        .activetype {
            border-bottom: 3px solid #65d3c2;
            margin-bottom: -11px;
            padding-bottom: 10px;
        }
    }
    .notifications_list {
        .notifications_list_inner {
            li.noty_li {
                /* background-color: #0bb19747; */
                padding: 10px 10px;
                /* border-bottom: 1px solid gray; */
                height: 70px;
                overflow: auto;
                background: linear-gradient(to bottom, rgb(165 170 169 / 7%), rgb(92 92 92 / 23%));
                span.noty_time {
                    color: #0bb197;
                }
                p.noty_text {
                    color: black;
                    overflow: hidden;
                    height: 22px;
                    width: 95%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-top: 5px;
                }
            }
        }
    }
}

// NotificationDropdown end

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
    padding: 0 0 0 10px;
}
.ag-theme-alpine .ag-ltr .ag-cell {
    /* border-right-width: 1px; */
    padding: 0px 8px;
}

.custom-header-center .ag-header-cell-label .ag-header-cell-text {
    display: flex;
    width: 100%;
    justify-content: center;
    /* text-align: center; */
    margin-right: 15px;
}

// addrole start=======================================

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper table {
    height: 44px;
}

.addRole {
    .back_roll_btn {
        :where(.css-dev-only-do-not-override-z1i11c).ant-btn-default.ant-btn {
            background-color: #0bb197;
            color: white;
            font-weight: 600;
            width: 35px;
            overflow: hidden;
            height: 35px;
        }
    }
    .addRoleHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px;
        height: 100px;
        background-color: white;
        padding: 10px 15px;
        border: 1px solid #d6d6d6;
        border-radius: 10px;
        input#roleName {
            font-size: 14px;
            padding: 5px 10px;
            width: 300px;
            border-radius: 5px;
            border: 1px solid #d3d2ca;
        }
        label.accessLable {
            margin: 36px 10px 0 0;
            font-size: 16px;
            font-weight: 400;
        }
    }

    .addRoleBody {
        display: block;
        height: calc(100vh - 33vh);
        border: 1px solid #d6d6d6;
        margin: 15px;
        border-radius: 10px;
    }
    .addRoleFooter {
        .addRole-btn {
            border: 1px solid;
            border-radius: 4px;
            box-shadow: none;
            background: none;
            font-size: 12px;
            height: 26px;
            width: 80px;
            margin: 10px;
        }
    }
}

// addrole end=======================================
// invoice & ImageInvoiceTable table start=====================================

.invoice-outer {
    display: block;
    .invoice-inner {
        display: block;
        .invoice-heading-outer {
            border-bottom: 2px solid #eff2f7;
            height: 10%;
            .invoice-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 13px;
                height: 40px;
                .beck-btn {
                    display: flex;
                    Button {
                        width: 25px;
                        height: 25px;
                        font-weight: 600;
                        font-size: 14px;
                        padding: 0 5px 0 9px;
                        span.anticon.anticon-arrow-left {
                            margin-left: -5px;
                        }
                    }
                    .invoice_heading_text svg {
                        width: 20px;
                        height: 20px;
                        margin: 2px 0 0 6px;
                    }
                }
                .Steps-div {
                    width: 550px;
                }
                .invoice-btn {
                    display: flex;
                    align-items: center;
                    .invoice-common {
                        margin: 0 0 0 10px;
                    }
                    .Configration-btn {
                        margin: 0 0 0 10px;
                        height: 26px;
                        padding: 0px 12px;
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .invoice_body_inner {
            display: flex;
            margin: 10px 10px 0;
            .manual_ivc_card {
                border: 1px solid #c8c8c8;
                height: calc(100vh - 100px);
                margin: 3px;
                border-radius: 5px;
                overflow-x: scroll;
            }
            .left_side_voucher {
                width: 65%;
                .left_first_card {
                    display: block;
                    border-bottom: 1px dashed #c8c8c8;
                    padding: 5px 15px;
                    .input_div {
                        display: flex;
                        justify-content: left;
                        margin: 15px 0;
                        .voucher_type {
                            display: flex;
                            flex-direction: column;
                            margin-right: 20px;
                            label {
                                display: inline-block;
                                margin-bottom: 7px;
                                font-size: 14px;
                                font-weight: 400;
                            }
                            .voucher_type_select {
                                width: 450px;
                                height: 28px;
                            }
                            .voucher_no_select {
                                width: 250px;
                                height: 28px;
                            }
                            .party_name_div {
                                display: flex;
                                .party_name_select {
                                    margin-right: 15px;
                                    width: 390px;
                                    height: 28px;
                                }
                                .party_name_btn {
                                    height: 28px;
                                }
                            }
                        }
                    }
                    .left_card_titel {
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    table.s_transaction-content-box-table-border {
                        width: 100%;
                        .common_tr {
                            height: 40px;
                            .comman_class_table_th {
                                border: 1px solid #d2d0d0;
                                text-align: center;
                                font-weight: 500;
                                font-size: 14px;
                                padding: 0 10px;

                                .add_row_btn {
                                    width: 20px;
                                    height: 20px;
                                    color: white;
                                    background-color: #0bb197;
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 5px;
                                }
                            }
                            .s_tw-5 {
                                width: 5%;
                            }
                            .s_tw-35 {
                                width: 25%;
                            }
                            .s_tw-10 {
                                width: 20%;
                            }
                            .s_tw-amount {
                                width: 20%;
                            }
                            .s_action-column {
                                width: 15%;
                            }
                            .s_tw_65 {
                                width: 65%;
                            }
                            .s_tw_35 {
                                width: 50%;
                            }
                            .s_tw_30 {
                                width: 25%;
                            }
                            .s_tw_15 {
                                width: 10%;
                            }
                        }
                        .table_heading_tr {
                            height: 40px;
                            .comman_class_table_th {
                                background-color: rgb(242 242 242);
                            }
                        }
                        .table_body_tr {
                            height: 50px;
                            .voucher_type_select {
                                width: 100%;
                            }
                        }
                        .table_total_tr {
                            height: 35px;
                            .comman_class_table_th {
                                text-align: left;
                                background-color: rgb(242 242 242);
                                .table_total {
                                    display: flex;
                                    justify-content: space-evenly;
                                }
                            }
                        }
                    }
                }
                .left_last_card {
                    display: flex;
                    padding: 5px 15px;
                    .narration_left_div {
                        width: 60%;
                        padding: 0 15px 0 0;
                        .left_card_titel {
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                        textarea {
                            overflow: auto;
                            resize: vertical;
                            width: -webkit-fill-available;
                            height: 150px;
                            outline: none;
                            border-color: #c8c8c8;
                        }
                    }
                    .narration_right_div {
                        width: 40%;
                        background-color: rgb(242 242 242);
                        margin: 10px 0;
                        .narration_Total_div {
                            display: flex;
                            justify-content: space-between;
                            margin: 25px 15px;
                            align-items: center;
                        }
                        .narration_Total {
                            height: 100px;
                            border-bottom: 1px dashed #c8c8c8;
                        }
                        .total_bgm {
                            font-weight: 700;
                        }
                    }
                }
            }
            .right_side_customer {
                width: 35%;
                .right_first_card {
                    display: block;
                    border-bottom: 1px dashed #c8c8c8;
                    .first_card_heding {
                        border-bottom: 1px solid #c8c8c8;
                        padding: 5px 10px;
                        background-color: #f0f0f0;
                        font-weight: 600;
                    }
                    .details {
                        display: flex;
                        padding: 5px 10px;
                        span {
                            width: 125px;
                        }
                    }
                    textarea {
                        overflow: auto;
                        resize: vertical;
                        margin: 10px;
                        border-color: #c8c8c8;
                        width: -webkit-fill-available;
                        height: 150px;
                        outline: none;
                    }
                }
                .right_second_card {
                    display: flex;
                    padding: 10px 5px;
                    border-bottom: 1px solid #c8c8c8;

                    .right_second_card_div {
                        border: 1px solid;
                        margin: 0 5px;
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        border-radius: 5px;
                    }
                    .right_second_card_lefy {
                        border-color: #aaffd2;
                        background: #eaf7f0;
                    }
                    .right_second_card_right {
                        background: #f9f5f5;
                        border-color: #d5d2d2;
                    }
                }
                .right_third_card {
                    display: block;
                    .right_third_heading {
                        border-bottom: 1px solid #c8c8c8;
                        padding: 5px 10px;
                        background-color: #f0f0f0;
                        font-weight: 600;
                    }
                    .right_third_space {
                        padding: 5px 10px;
                    }
                }
            }
        }
        .invoice_footer_outer {
            .invoice-footer {
                display: flex;
                justify-content: end;
                margin: 0 10px;
                .invoice-btn {
                    margin: 5px 5px;
                }
            }
        }
    }
}
// invoice & ImageInvoiceTable table end=======================================
